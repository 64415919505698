<template>
  <div class="p-2">
    <div
      class="relative w-36 bg-bg_alt_color rounded-2xl shadow-item cursor-pointer transition-transform duration-300 transform hover:scale-105 overflow-hidden border-2"
      :class="[active ? 'border-nav_color' : 'border-transparent']"
      @click="emit('click', category.id)"
    >
      <NuxtImg
        provider="cacheIpx"
        preset="modified"
        loading="lazy"
        class="w-full aspect-square object-cover"
        width="216"
        height="216"
        :src="category.image"
        :alt="category.name"
      />

      <div
        class="absolute bottom-0 left-0 right-0 top-1/2 bg-gradient-to-t from-black to-transparent"
      ></div>
      <div
        class="absolute inset-x-0 bottom-1 text-center text-sm font-medium text-white"
      >
        {{ category.name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Category } from '~/composables/useMenuModel'

defineProps<{
  active: boolean
  category: Category
}>()

const emit = defineEmits(['click'])
</script>
